<template>
  <div class="flex h-full items-center tabular-nums" :class="params.justifyStart ? 'justify-start' : 'justify-end'">
    <span v-if="millionValue">
      {{ millionValue }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const millionValue = computed(() => {
  if (props.params.value && !isNaN(props.params.value)) {
    return formatNumber(props.params.value);
  }
});
</script>
